<template>
  <div class="container_index">
    <div class="top">
      <div>{{ schoolData.schoolName }}</div>
      <!-- <div style="margin-left: 190px">
        <span style="font-size: 16px">全院人数:</span> {{schoolStudents}}<span
          style="font-size: 16px"
          >人</span
        >
      </div>
      <div style="margin-left: 75px">
        <span style="font-size: 16px">老年卡数:</span> {{schoolDevices}}<span
          style="font-size: 16px"
          >人</span
        >
      </div> -->
      <!-- <div style="margin-left: 75px">
        <span style="font-size: 16px">在线率:</span
        ><span style="color: #5d9cff"> 99</span
        ><span style="font-size: 16px">%</span>
      </div> -->
      <div class="user">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <span style="font-size: 16px">{{ schoolData.schoolManager }}</span>
            <img src="../assets/ico_down.png" style="margin-left: 15px" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="info">
      <div
        style="
          width: 5px;
          height: 40px;
          background: #a2c8fa;
          margin-right: 20px;
        "
      ></div>
      <!-- <img src="../assets/ico_xiaoxi.png" style="margin-right: 10px" />
      <div>消息：</div>
      <img
        src="../assets/but_sos.png"
        style="position: absolute; right: 40px"
      /> -->
      <!-- <div class="detailRight" style="position: absolute; right: 40px">
        允许上级单位查看本院智慧老年卡相关信息
        <el-switch
          style="margin-left: 10px; margin-top: 2px"
          v-model="allow"
          active-color="#5D9CFF"
          inactive-color="#a5a5a5"
        >
        </el-switch>
      </div> -->
      <div class="details">
        基础信息<img
          src="../assets/ico_you.png"
          style="margin-top: 2px"
        />院区管理
      </div>
    </div>
    <div class="menu">
      <div class="head">
        <img
          :src="
            schoolData.schoolPhotoUrl == ''
              ? require('../assets/school.png')
              : schoolData.schoolPhotoUrl
          "
          style="margin-left: 23px; width: 115px; height: 115px"
        />
      </div>
      <div class="item" @click="$router.push('/index')">
        <img
          src="../assets/ico_shouye.png"
          style="margin-right: 4px"
        />&nbsp;首&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;页
      </div>
      <div
        class="item"
        style="
          background: #f5f6fa;
          color: #5d9cff;
          border-bottom: 1px solid #b9d5fa;
        "
        @click="$router.push('/basicManage')"
      >
        <div style="display: flex; align-items: center">
          <img
            style="margin-right: 10px"
            src="../assets/ico_jichuguanli_blue.png"
          />基础管理
        </div>
      </div>
      <div
        class="item-white"
        style="
          color: #5d9cff;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
      >
        <div style="display: flex; align-items: center">院区管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/gradeManage')"
      >
        <div style="display: flex; align-items: center">年段管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/classManage')"
      >
        <div style="display: flex; align-items: center">房间管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/teacherManage')"
      >
        <div style="display: flex; align-items: center">负责人管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/studentManage')"
      >
        <div style="display: flex; align-items: center">老人管理</div>
      </div>
      <div class="item" @click="$router.push('/teacherApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />负责人审核
      </div>
      <div class="item" @click="$router.push('/studentApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />老人审核
      </div>
      <div class="item" @click="$router.push('/deviceManage')">
        <img
          style="margin-right: 10px"
          src="../assets/devices_white.png"
        />设备管理
      </div>
      <div class="item" @click="$router.push('/signManage')">
        <img
          style="margin-right: 10px"
          src="../assets/signin_white.png"
        />签到管理
      </div>
      <div class="item" @click="$router.push('/leaveManage')">
        <img
          style="margin-right: 10px"
          src="../assets/leave_white.png"
        />请假管理
      </div>
      <div class="item" @click="$router.push('/noticeManage')">
        <img
          style="margin-right: 10px"
          src="../assets/speaker_white.png"
        />院方公告
      </div>
    </div>
    <div class="content">
      <div class="card">
        <div class="inner">
          <div
            style="
              font-size: 16px;
              margin-bottom: 35px;
              margin-top: 35px;
              position: relative;
            "
          >
            <span>院区名称：</span>
            <el-input
              v-model="inputSchool"
              placeholder="请输入"
              style="width: 230px"
              clearable
            ></el-input>
            <span style="margin-left: 80px">负责人：</span>
            <el-input
              v-model="inputManager"
              placeholder="请输入"
              style="width: 230px"
              clearable
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              style="position: absolute; right: 20px; width: 100px"
              @click="search"
              >搜索</el-button
            >
          </div>
          <div style="font-size: 16px; margin-bottom: 35px; position: relative">
            数据列表
            <!-- <el-button
              type="warning"
              style="position: absolute; right: 130px; width: 100px"
              >导入</el-button
            > -->
            <el-button
              type="success"
              style="position: absolute; right: 130px; width: 100px"
              @click="exportExcel('表单')"
              >导出</el-button
            >
            <el-button
              type="primary"
              style="position: absolute; right: 20px; width: 100px"
              @click="
                dialogFormVisible = true;
                dialogFormVisibleUpdate = false;
                clearForm();
              "
              >新增</el-button
            >
          </div>
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            :header-cell-style="{ background: '#E0EDFF', color: '#606266' }"
            max-height="420px"
            ref="table"
          >
            <el-table-column
              type="index"
              label="序号"
              width="180"
              align="center"
            >
            </el-table-column>
            <!-- <el-table-column
              prop="name"
              label="院区名称"
              width="180"
              align="center"
            >
            </el-table-column> -->
            <el-table-column
              prop="schoolName"
              label="院区名称"
              width="180"
              align="center"
            >
            </el-table-column>
            <!-- <el-table-column
              prop="campusName"
              label="院区名称"
              width="180"
              align="center"
              v-else
            >
            </el-table-column> -->
            <el-table-column
              prop="schoolManager"
              label="负责人"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="schoolManagerPhone"
              label="联系电话"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="allowParentLook"
              label="上级查看"
              align="center"
            >
              <template slot-scope="scope">
                <span>{{ allowLook[scope.row.allowParentLook] }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="method" label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="editRow(scope.$index, tableData)"
                  type="text"
                  size="small"
                >
                  编辑
                </el-button>
                <el-button
                  @click.native.prevent="deleteRow(scope.$index, tableData)"
                  type="text"
                  size="small"
                  style="color: red"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog
            :title="dialogFormVisibleUpdate ? '编辑院区' : '新增院区'"
            :visible.sync="dialogFormVisible"
            :width="'30%'"
            :close-on-click-modal="false"
          >
            <el-form :model="form" :rules="rules" ref="form">
              <!-- <el-form-item
                label="院区编号"
                :label-width="formLabelWidth"
                prop="schoolId"
                v-if="!dialogFormVisibleUpdate"
              >
                <el-input v-model="form.schoolId" autocomplete="off"></el-input>
              </el-form-item> -->
              <!-- <el-form-item label="院区名称" :label-width="formLabelWidth">
                <el-input v-model="form.name" autocomplete="off"></el-input>
              </el-form-item> -->
              <el-form-item
                label="院区名称"
                :label-width="formLabelWidth"
                prop="schoolName"
              >
                <el-input
                  v-model="form.schoolName"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="负责人"
                :label-width="formLabelWidth"
                prop="schoolManager"
              >
                <el-input
                  v-model="form.schoolManager"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="联系电话"
                :label-width="formLabelWidth"
                prop="schoolManagerPhone"
              >
                <el-input
                  v-model="form.schoolManagerPhone"
                  autocomplete="off"
                  placeholder="登录账号"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="登录密码"
                :label-width="formLabelWidth"
                prop="schoolPassword"
              >
                <el-input
                  v-model="form.schoolPassword"
                  placeholder="请输入6-15位数字和字母"
                  autocomplete="off"
                  type="password"
                ></el-input>
              </el-form-item>
              <div class="details" style="font-size: 12px; margin-left: 82px">默认登录密码: 123456a</div>
              <!-- <el-form-item
                label="上级查看"
                :label-width="formLabelWidth"
                prop="allowParentLook"
              >
                <el-switch
                  style="margin-left: 10px; margin-top: 2px"
                  v-model="form.allowParentLook"
                  active-color="#5D9CFF"
                  inactive-color="#a5a5a5"
                >
                </el-switch>
              </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button
                @click="
                  dialogFormVisible = false;
                  dialogFormVisibleUpdate == false;
                "
                >取 消</el-button
              >
              <el-button
                type="primary"
                @click="validateForm"
                v-if="dialogFormVisibleUpdate == false"
                >确 定</el-button
              >
              <el-button
                type="primary"
                @click="validateFormUpdate"
                v-if="dialogFormVisibleUpdate == true"
                >确 定</el-button
              >
            </div>
          </el-dialog>
          <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="30%"
            append-to-body
          >
            <span>提交信息有误</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
          <el-dialog title="提示" :visible.sync="showErrorAlert" width="30%">
            <span>{{ errorMessage }}</span>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="showErrorAlert = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from "file-saver";
import XLSX from "xlsx";
import encrypt from '@/Api/encrypt';
export default {
  name: "basicManage",
  components: {},
  data() {
    return {
      schoolDevices: localStorage.getItem("schoolDevices"),
      schoolStudents: localStorage.getItem("schoolStudents"),
      mapType: "class",
      schoolData: JSON.parse(localStorage.getItem("schoolData")),
      allow: false,
      dialogVisible: false,
      showErrorAlert: false,
      errorMessage: "",
      inputSchool: "",
      inputManager: "",
      form: {
        schoolId: "",
        schoolName: "",
        schoolManager: "",
        schoolManagerPhone: "",
        schoolPassword: "123456a",
        allowParentLook: false,
      },
      rules: {
        schoolPassword: [
          { required: true, message: "请输入院区密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
          {
            pattern: "^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,15}$",
            message: "密码至少包含数字和英文",
            trigger: "blur",
          },
        ],
        schoolName: [
          { required: true, message: "请输入院区名称", trigger: "blur" },
        ],
        schoolManager: [
          { required: true, message: "请输入负责人姓名", trigger: "blur" },
        ],
        schoolManagerPhone: [
          { required: true, message: "请输入负责人电话", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
      },
      formLabelWidth: "80px",
      dialogFormVisible: false,
      dialogFormVisibleUpdate: false,
      // tableData: [],
      allowLook: ["不允许", "允许"],
      tableData: [],
      options: [
        {
          value: "选项1",
          label: "院区1",
        },
        {
          value: "选项2",
          label: "院区2",
        },
        {
          value: "选项3",
          label: "院区3",
        },
        {
          value: "选项4",
          label: "院区4",
        },
        {
          value: "选项5",
          label: "院区5",
        },
      ],
      value: "",
      searched: false,
    };
  },
  mounted() {
    // this.drawChart();
    // this.drawChart1();
    // this.drawChart2();
    // this.drawChartLeft1();/school/info/getList?page=1&size=10
    this.$http
      .get(
        `${this.$baseUrl}/school/info/getSchoolByParentId?page=1&size=100&parentId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        console.log(response.data.result.records);
        this.tableData = response.data.result.records;
      });
  },
  methods: {
    clearForm() {
      this.form.schoolPassword = "123456a";
      this.form.schoolId = "";
      this.form.schoolName = "";
      this.form.schoolManager = "";
      this.form.schoolManagerPhone = "";
      this.form.allowParentLook = false;
    },
    search() {
      if (this.inputSchool.length < 1 && this.inputManager.length < 1) {
        this.searched = false;
        this.$http
          .get(
            `${this.$baseUrl}/school/info/getSchoolByParentId?page=1&size=100&parentId=${this.schoolData.schoolId}`
          )
          .then((response) => {
            console.log(response.data.result.records);
            this.tableData = response.data.result.records;
          });
        return;
      }
      this.$http
        .get(
          `${this.$baseUrl}/school/info/findSchools?page=1&size=100${
            this.inputSchool.length > 0 ? "&schoolName=" + this.inputSchool : ""
          }${
            this.inputManager.length > 0
              ? "&schoolManager=" + this.inputManager
              : ""
          }&parentId=${this.schoolData.schoolId}&schoolId=${
            this.schoolData.schoolId
          }`
        )
        .then((response) => {
          console.log(response.data.result.records);
          this.searched = true;
          this.tableData = response.data.result.records;
        });
    },
    deleteRow(index, data) {
      this.$http
        .delete(`${this.$baseUrl}/school/info/delete/${data[index].schoolId}`)
        .then((response) => {
          if (response.data.code != 200) {
            this.errorMessage = "存在数据绑定关系，不可删除";
            this.showErrorAlert = true;
            return;
          }
          this.$http
            .get(
              `${this.$baseUrl}/school/info/getSchoolByParentId?page=1&size=100&parentId=${this.schoolData.schoolId}`
            )
            .then((response) => {
              console.log(response.data.result.records);
              this.tableData = response.data.result.records;
            });
        });
    },
    editRow(index, data) {
      this.dialogFormVisible = true;
      this.dialogFormVisibleUpdate = true;
      this.form.schoolId = data[index].schoolId;
      this.form.schoolName = data[index].schoolName;
      this.form.schoolManager = data[index].schoolManager;
      this.form.schoolManagerPhone = data[index].schoolManagerPhone;
      this.form.schoolPassword = data[index].password;
      this.form.allowParentLook =
        data[index].allowParentLook == 0 ? false : true;
      console.log(this.dialogFormVisible);
    },
    exportExcel(excelName = "表单") {
      //excelName --设置导出的excel名称
      //report-table --对应的要导出的el-table的ref名称
      try {
        const $e = this.$refs["table"].$el;
        // 如果表格加了fixed属性，则导出的文件会生产两份一样的数据，所以可在这里判断一下
        let $table = $e.querySelector(".el-table__fixed");
        if (!$table) {
          $table = $e;
        }
        // 为了返回单元格原始字符串，设置{ raw: true }
        const wb = XLSX.utils.table_to_book($table, { raw: true });
        const wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
        });
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          `${excelName}.xlsx`
        );
      } catch (e) {
        if (typeof console !== "undefined") console.error(e);
      }
    },
    validateForm() {
      this.$refs["form"].validate((valid) => {
        this.form.schoolId =
          this.form.schoolManagerPhone + "School" + +new Date();
        if (valid) {
          let schoolPassword = encrypt.Encrypt(this.form.schoolPassword, 'vargovargovargo1');
          this.$http
            .post(`${this.$baseUrl}/school/info/add`, {
              schoolId: this.form.schoolId,
              schoolName: this.form.schoolName,
              province: this.schoolData.province,
              city: this.schoolData.city,
              area: this.schoolData.area,
              parentId: this.schoolData.schoolId,
              schoolManager: this.form.schoolManager,
              schoolManagerPhone: this.form.schoolManagerPhone,
              account: this.form.schoolManagerPhone,
              password: schoolPassword,
              allowParentLook: this.form.allowParentLook == false ? 0 : 1,
            })
            .then((response) => {
              this.dialogFormVisible = false;
              this.$refs["form"].resetFields();
              console.log(response);
              if (response.data.code != 200) {
                this.dialogVisible = true;
              }
              this.$http
                .post(`${this.$baseUrl}/school/time/config/add`, {
                  schoolId: this.form.schoolId,
                  termType: 0,
                  timeConfigId:
                    this.form.schoolId +
                    "_" +
                    new Date(Date.now()).getFullYear() +
                    "_" +
                    "0",
                })
                .then((response) => {
                  if (response.data.code != 200) {
                    this.errorMessage = response.data.message;
                    this.showErrorAlert = true;
                    return;
                  }
                  this.$http
                    .post(`${this.$baseUrl}/school/time/config/add`, {
                      schoolId: this.form.schoolId,
                      termType: 1,
                      timeConfigId:
                        this.form.schoolId +
                        "_" +
                        new Date(Date.now()).getFullYear() +
                        "_" +
                        "1",
                    })
                    .then((response) => {
                      if (response.data.code != 200) {
                        this.errorMessage = response.data.message;
                        this.showErrorAlert = true;
                        return;
                      }
                      this.$http
                        .get(
                          `${this.$baseUrl}/school/info/getSchoolByParentId?page=1&size=100&parentId=${this.schoolData.schoolId}`
                        )
                        .then((response) => {
                          console.log(response.data.result.records);
                          this.tableData = response.data.result.records;
                        });
                    });
                });
            })
            .catch(() => {
              this.dialogVisible = true;
            });
        } else {
          return false;
        }
      });
    },
    validateFormUpdate() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let schoolPassword = encrypt.Encrypt(this.form.schoolPassword, 'vargovargovargo1');
          this.$http
            .put(`${this.$baseUrl}/school/info/update`, {
              schoolId: this.form.schoolId,
              schoolName: this.form.schoolName,
              province: this.schoolData.province,
              city: this.schoolData.city,
              area: this.schoolData.area,
              parentId: this.schoolData.schoolId,
              schoolManager: this.form.schoolManager,
              schoolManagerPhone: this.form.schoolManagerPhone,
              account: this.form.schoolManagerPhone,
              password: schoolPassword,
              allowParentLook: this.form.allowParentLook == false ? 0 : 1,
            })
            .then((response) => {
              this.dialogFormVisible = false;
              this.dialogFormVisibleUpdate = false;
              this.$refs["form"].resetFields();
              console.log(response);
              if (response.data.code != 200) {
                this.dialogVisible = true;
              }
              this.$http
                .get(
                  `${this.$baseUrl}/school/info/getSchoolByParentId?page=1&size=100&parentId=${this.schoolData.schoolId}`
                )
                .then((response) => {
                  console.log(response.data.result.records);
                  this.tableData = response.data.result.records;
                });
            })
            .catch(() => {
              this.dialogVisible = true;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.item-white {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: pre;
  line-height: 22px;
  background: #f5f6fa;
}
.item-white:hover {
  background: #d2e3ff;
}

.right_items {
  margin-bottom: 16px;
  width: 414px;
  position: relative;
}
.inner {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  text-align: left;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}
.card {
  margin: 10px;
  background-color: white;
  width: 1700px;
  height: 600px;
  border-radius: 9px;
}

.detailRight {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;
}

.item:hover {
  background-color: #85b4ff;
}

.smallTitle {
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.mainContainer {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
}

.rates {
  margin-left: 20px;
  display: flex;
  position: absolute;
  width: 40%;
  float: left;
  margin-top: 10px;
  height: calc(100% - 70px);
}
#main {
  width: 100%;
  float: right;
  height: calc(100% - 60px);
}

#mainLeft1 {
  display: grid;
  width: 50%;
  height: 100%;
}
#mainLeft2 {
  display: grid;
  width: 50%;
  height: 100%;
}

#main1 {
  width: 100%;
  height: 100%;
}

#main2 {
  width: 100%;
  height: 80%;
}

.selector {
  width: 95%;
  margin-top: 10px;
}

.smallSelector {
  width: 60px;
}

.smallSelector /deep/ .el-input__inner {
  height: 20px;
}
/* 下面设置右侧按钮居中 */
.smallSelector /deep/ .el-input__suffix {
  top: 1px;
}
.smallSelector /deep/ .el-input__icon {
  line-height: inherit;
}
.smallSelector /deep/ .el-input__suffix-inner {
  display: inline-block;
}
.user {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  position: absolute;
  right: 40px;
}

.content {
  margin-left: 180px;
  width: calc(100% - 180px);
  height: calc(100% - 170px);
  position: relative;
  top: 170px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
}

.top {
  background-color: white;
  position: absolute;
  top: 0;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 120px;
  text-align: left;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 33px;
  display: flex;
  align-items: center;
}

.info {
  background-color: white;
  position: absolute;
  top: 120px;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 60px;
  text-align: left;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  display: flex;
  align-items: center;
  background: #f5f6fa;
}

.details {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #666666;
}

.left {
  float: left;
  height: calc(100% - 50px);
  width: calc(100% - 590px);
  margin-left: 30px;
}
.right {
  float: right;
  position: absolute;
  right: 0;
  margin-right: 40px;
  height: calc(100% - 50px);
  /* display: flex;
  flex-direction: column; */
}

.topRight {
  flex: auto;
  height: 42%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
}

.midRight {
  flex: auto;
  height: 22%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}

.bottomRight {
  flex: auto;
  height: 31%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
}

.container_index {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 1900px;
  min-height: 920px;
  background: #f5f6fa;
}

.map {
  box-shadow: 0px 2px 4px 0px #b9d5fa;
}

.head {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: pre;
  line-height: 22px;
}

.menu {
  position: absolute;
  width: 160px;
  height: 100%;
  background-color: #5d9cff;
}
</style>