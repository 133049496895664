import { render, staticRenderFns } from "./teacherManage.vue?vue&type=template&id=8f94dfda&scoped=true&"
import script from "./teacherManage.vue?vue&type=script&lang=js&"
export * from "./teacherManage.vue?vue&type=script&lang=js&"
import style0 from "./teacherManage.vue?vue&type=style&index=0&id=8f94dfda&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f94dfda",
  null
  
)

export default component.exports